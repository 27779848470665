.header{
  top:6vh;
  padding-left: 70px;
  position: fixed !important;
  width:25vw;
  height: 100%;
}
.content{
  float: right;
  position: absolute;
  left: 370px;
  width: 60vw;
}
.logo{
  white-space: nowrap;
  margin-bottom: 40px;
}
a{
  color: black;
}
h1{
  font-size: 2.5rem;
  font-weight: 500;
  margin-block-start: 0;
  margin-block-end: 0;
}
h2{
  font-size: 2.5rem;
  font-weight: 500;
  margin-block-start: 0;
  margin-block-end: 0;
}

@media only screen and (max-width: 1024px){
  .header{
    left: 5vw !important;
    background-color: white;
    top: 0;
    padding-left: 0 !important;
    height: auto;
    width: 90vw;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .logo{
    font-size: 1.2rem;
    padding-top: 16px;
    margin-bottom: 3vh;
    text-align: left;
  }
  .menuBtn{
    position: absolute;
    font-size: 1.2rem;
    right: 0;
    padding-top: 16px;
    z-index: 1;
  }
  .content{
    z-index:-1;
    left:5vw;
    float: none;
    display: flex;
    justify-content: center;
    width: 90vw;
  }

  .jsSticky {
    width: 100vw;
    height: 60px;
    background: firebrick;
    position: relative;
  }

  .sticky {
    position: fixed !important;
    top: 0;
    width: 100vw;
  }

}

@supports (position: sticky) or (position: -webkit-sticky) {
  @media (max-width: 1024px) {
    .jsSticky {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
  }
}
@media only screen and (min-width: 1025px){
  .menuBtn{
    display: none;
  }
}