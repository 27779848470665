.headline{
    color: darkgray;
    margin-bottom: 40px;
}
.thumbnail{
    display: inline-block;
    cursor:pointer;
    color: darkgray;
    margin-inline-end: 60px;
    margin-inline-start: 0;
    margin-left: 0;
    margin-bottom: 3rem;
}
.thumbnail:hover{
    opacity: 0.7;
}
.thumbnailNumber{
    display: flex;
    justify-content: center;
    font-size: x-small;
    margin-top:15px;
}
.projects{
    padding-top: 6vh;
}
.last{
    min-height: 100vh;
}

@media only screen and (max-width: 1024px) {
    .headline{
        margin-bottom: 0vh;
        font-size: 1.2rem;
        max-width: fit-content;
        overflow: hidden;
    }
    .thumbnailNumber{
        display: none;
    }
    .thumbnail{
        display: inline-block;
        margin-right: 0px;
        pointer-events: none;
        margin-bottom: 2rem;

    }
    img{
        width: 90vw;
        height: auto;
    }

    .projects{
        padding-top: 0vh;
        margin-bottom: 4rem;
        position: relative;
    }
    .projectTxt{
        position: relative;
    }
    .toggleText{
        bottom: -32px;
        position: absolute;
        display: block;
        border-radius: 3px;
        background-color: transparent;
        border: black 1px solid;
    }
    .moreText{
        display: none;
    }
}
@media only screen and (min-width: 1025px) {
    .projectTxt, .more, .less{
        display: none;
    }
}


