.infoSection{
    padding-top: 6vh;
    width: 75%;
}
#imprint{
    min-height: 100vh;
}
h3{
    margin-top: 3rem;
    font-size: 1rem;
}

.listEl{
    font-size: small;
    margin-left: 20px;
}
.address{
    font-size: small;
    margin-left: 20px;
}
.listEl li{
    margin-top: 15px;
    margin-bottom: 20px;
}
.action:hover{
    color: lightgray;
}
p{
    font-size: 16px;
}
@media only screen and (max-width: 1024px) {
    .infoSection{
        width: 90vw;
        padding-top: 0;
        padding-bottom: 8vh;
    }
    h3{
        margin-block-start: 1.3em;
        margin-block-end: 1.3em;
    }

    .listEl li{
        margin-top: 5px;
    }
}
