.current{
    background-color: yellow;
}
.navHeadline{
    color: darkgray;
    margin-top: 40px;
}
.navigation{
    margin-left: 10px;
}
ul{
    list-style: none;
    padding: 0;
}
@media only screen and (max-width: 1024px){
    #nav{
        display: flex !important;
        justify-content: space-between;
        position:absolute;
        width: 90vw;
        height: 0;
        overflow: hidden;
        background-color: rgba(255,255,255,0.9);
        transition: height 0.2s ease-in;

    }
    #nav ul{
        font-size: 1.2rem;
        margin: 0;
    }
    .opened{
        height: 100vh !important;
    }
    .navHeadline{
        margin-bottom: 0;
        margin-top: 0;
    }
    #headlineInfo{
        margin-top:4vh;
    }
    .navigation{
        margin-left: 0;
        margin-right: 0;
    }

}

