*{
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: 'Roboto Mono', monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'),
  url(./fonts/roboto-mono-v5-latin/roboto-mono-v5-latin-500.ttf) format('truetype'),
  url(./fonts/roboto-mono-v5-latin/roboto-mono-v5-latin-500.woff2) format('truetype'),
  url(./fonts/roboto-mono-v5-latin/roboto-mono-v5-latin-500.woff) format('truetype');
}

body {
  margin: 0;
  line-height: 1.5;
}
button{
  outline:none;
}




