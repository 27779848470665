.headlineDetail{
    color: darkgray;
    margin-bottom: 40px;
}
img{
    display: block;
}
.detailImg{
    margin: 0 0 1rem;
}
.galleryNav{
    font-size: x-small;
    display: inline;
    margin-top:15px;
}
.btn{
    background: none;
    border: none;
}
#backbtn{
    padding: 0;
    font-size: x-small;
    margin-bottom: 15px;
}
#prevBtn{
    padding-left: 0;
}
.detailTxt{
    width: 70%;
    color: darkgray;
    margin-top: 45px;
    font-size: small;
}
.project{
    padding-top: 6vh;
}
@media only screen and (max-width: 1024px) {
    .headlineDetail{
        color: darkgray;
        margin-bottom: 0vh;
        font-size: 1rem;
    }
    .project{
        padding-top: 0;
    }
    .detailTxt{
        width: 90vw;
    }
}
